<template>
    <main>
        <div class="ui center aligned container">
            <img class="ui centered medium logo image" src="~@/assets/images/logo/1991spwg.png"/>
            <div class="ui hidden divider"></div>
            <div class="ui compact center aligned very padded segment" :class="segment_class">
                <div v-if="token">
                    <template v-if="status === 'verifying'">
                        <h1 class="ui poppins weight-400 header" style="font-size: 2.25rem;">Verifying Email</h1>
                        <p class="ui grey text">Please wait while we verify your email with our server.</p>
                    </template>
                    <template v-else-if="status === 'success'">
                        <div class="ui center aligned text">
                            <i class="check circle massive teal icon"></i>
                        </div>
                        <h1 class="ui poppins weight-400 header" style="font-size: 2.25rem;">Email Verified!</h1>
                        <p class="ui grey text">Next, log in to your account using password provided by system administrator.</p>
                        <div class="ui hidden divider"></div>
                        <p>
                            <router-link :to="{name: 'Login'}" class="ui teal button">GO TO LOGIN PAGE</router-link>
                        </p>
                    </template>
                    <template v-else-if="status === 'fail'">
                        <h1 class="ui poppins weight-400 red header" style="font-size: 2.25rem;">Verification Failed!</h1>
                        <p class="ui grey text">We encountered an error while verifying your email.</p>
                        <div class="ui error message" v-show="error"><i class="info circle icon"></i> {{error}}</div>
                        <div class="ui hidden divider"></div>
                        <p><button class="ui black button" type="button" @click="verify">RETRY</button></p>
                    </template>
                </div>
                <div v-else>
                    <h1 class="ui poppins weight-400 header" style="font-size: 2.25rem;">401 Unauthorized</h1>
                    <p class="ui grey text">You are not authorized on this page.</p>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            status: "verifying",
            token: this.$route.query.u || "",
            error: ""
        };
    },
    computed: {
        segment_class() {
            return {
                loading: this.token && this.status === "verifying",
                red: !this.token || this.status === "fail",
                teal: this.token && this.status !== "fail"
            };
        }
    },
    mounted() {
        this.verify();
    },
    methods: {
        verify() {
            this.status = "verifying";
            this.$http.post("users/verify-email", {
                token: this.token
            }).then((response) => {
                this.status = "success";
            }).catch((error) => {
                this.status = "fail";
                this.error = error.response.data || "";
                console.error(error.response);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
main {
    height: 100vh;
    background: #f7f8fa;

    .ui.compact.segment {
        margin: auto;
        max-width: calc(100vw - 2rem);
    }
}
</style>